<template>
  <div class="collaboration-area">
    <div class="collaboration-area-form">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item :label="$t('songName')" prop="title">
          <el-input v-model="ruleForm.title" :placeholder="$t('songname')" disabled></el-input>
        </el-form-item>
        <el-form-item :label="$t('producer')" prop="creator">
          <el-input v-model="ruleForm.creator" disabled></el-input>
        </el-form-item>
        <el-form-item label="AR" prop="url">
         <!-- <el-upload
              class="upload-demo"
              action="https://jsonplaceholder.typicode.com/posts/"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              multiple
              :limit="3"
              :on-exceed="handleExceed"
              :file-list="fileList">
            <el-button size="small" plain>{{ $t("clickUpload") }}</el-button> -->
			<el-upload class="upload-demo" :before-upload="testformatAr" :action="uploadMusicFileUrl"
				:on-preview="handlePreview" :on-remove="removeAR"
				:on-success="callbackAR" ref="AR" multiple :limit="1" :on-exceed="handleExceed"
				:file-list="fileList">
				<el-button size="small" plain>
					<div class="btn-wrap">
						<Icon name="icon-7key-musicshangchuan" theme-color="iconColorAll" width="16" height="16"></Icon>
						<div class="btn-wrap-text">{{ $t("clickUpload") }}</div>
					</div>
				</el-button>
				<!-- <span slot="tip" class="el-upload__tip">支持mp3，大小不超过100M</span> -->
			</el-upload>
            <!--            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>-->
          </el-upload>
        </el-form-item>
        <el-form-item label="MR" prop="MRUrl">
          <!-- <el-upload
              class="upload-demo"
              action="https://jsonplaceholder.typicode.com/posts/"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              multiple
              :limit="3"
              :on-exceed="handleExceed"
              :file-list="fileList">
            <el-button size="small" plain>{{ $t("clickUpload") }}</el-button> -->
			<el-upload class="upload-demo" :before-upload="testformatMr" :action="uploadMusicFileUrl"
				:on-preview="handlePreviewMR" :on-remove="removeMR"
				:on-success="callbackMR" ref="MR" multiple :limit="1" :on-exceed="handleExceedMR"
				:file-list="fileListMR">
				<el-button size="small" plain>
					<div class="btn-wrap">
						<Icon name="icon-7key-musicshangchuan" theme-color="iconColorAll" width="16" height="16"></Icon>
						<div class="btn-wrap-text">{{ $t("clickUpload") }}</div>
					</div>
				</el-button>
				<!-- <span slot="tip" class="el-upload__tip">支持mp3，大小不超过100M</span> -->
			</el-upload>
            <!--            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>-->
          </el-upload>
        </el-form-item>
        <el-form-item label="Multi" prop="MultiUrl">
         <!-- <el-upload
              class="upload-demo"
              action="https://jsonplaceholder.typicode.com/posts/"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              multiple
              :limit="3"
              :on-exceed="handleExceed"
              :file-list="fileList">
            <el-button size="small" plain>{{ $t("clickUpload") }}</el-button> -->
			<el-upload class="upload-demo" :before-upload="testformatMulti" :action="uploadMusicFileUrl"
				:on-preview="handlePreviewMulti" :on-remove="removeMulti"
				:on-success="callbackMulti" ref="Multi" multiple :limit="1" :on-exceed="handleExceedMulti"
				:file-list="fileListMulti">
				<el-button size="small" plain>
					<div class="btn-wrap">
						<Icon name="icon-7key-musicshangchuan" theme-color="iconColorAll" width="16" height="16"></Icon>
						<div class="btn-wrap-text">{{ $t("clickUpload") }}</div>
					</div>
				</el-button>
				<!-- <span slot="tip" class="el-upload__tip">支持mp3，大小不超过100M</span> -->
			</el-upload>
			
            <!--            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>-->
          </el-upload>
        </el-form-item>
         <el-form-item label="BPM" prop="bpm">
          <el-input v-model="ruleForm.bpm" :placeholder="$t('songspeed')"></el-input>
        </el-form-item>
        <el-form-item label="KEY" prop="key">
          <el-cascader
              :options="keysarr"
              :props="{multiple:false}"
			  @change="changeKeyCascader"
			  v-model="keyValue"
			   popper-class="reset-popper-bg-color"
              clearable :placeholder="$t('songtone')"></el-cascader>
			  <!-- <el-select ref="key" v-model="ruleForm.key" placeholder="请选择您歌曲的准确音调">
			  	<el-option v-for="item in keysarr" :key="item.toneId" :label="item.key"
			  		:value="item.toneId">
			  	</el-option>
			  </el-select> -->
        </el-form-item>
        <el-form-item :label="$t('cover')" prop="imgeUrl">
          <!-- <el-upload
              class="avatar-uploader"
              action="https://jsonplaceholder.typicode.com/posts/"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">{{ $t('uploadTip') }}</div>
          </el-upload> -->
		  <div class="upimg coverup">
			  <!-- <el-upload action="http://ht.7keymusic.com/api/uploadImage" list-type="picture-card"
			  	name="image" :file-list="coverlist" :before-upload="testformatCover"
			  	:on-success="callbackupimg" :limit=1 ref="cover">
			  	<i slot="default" class="el-icon-plus"></i>
			  	<div slot="file" slot-scope="{file}">
			  		<img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
			  		<span class="el-upload-list__item-actions">
			  
			  			<span v-if="!disabled" class="el-upload-list__item-delete"
			  				@click="handleRemove(file)">
			  				<i class="el-icon-delete"></i>
			  			</span>
			  		</span>
			  	</div>
			  </el-upload> -->
			  <el-upload :action="uploadImageUrl" list-type="picture-card" :on-success="callbackcover" :limit="1"
			  	:before-upload="testformatCover" name="image" ref="upload">
			  	<i slot="default" class="el-icon-plus"></i>
			  	<div slot="file" slot-scope="{ file }">
			  		<img class="el-upload-list__item-thumbnail" :src="file.url" alt=""/>
			  		<span class="el-upload-list__item-actions">
			  			<span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
			  				<i class="el-icon-delete"></i>
			  			</span>
			  		</span>
			  	</div>
			  </el-upload>
		  </div>
		 
        </el-form-item>
       <el-form-item :label="$t('singSinger')">
       	<el-cascader :options="singerList" :placeholder="$t('pleaseSelectSuitSinger')" :props="{multiple:false}" v-model="singerValue"
       		@change="changeSingerCascader" popper-class="reset-popper-bg-color" clearable></el-cascader>
       </el-form-item>
       <el-form-item :label="$t('songStyle')">
       	<el-cascader :options="styleList" :placeholder="$t('pleaseSelectSongStyle')" :props="{multiple:true}" v-model="styleValue"
       		@change="changeStyleCascader" popper-class="reset-popper-bg-color" clearable></el-cascader>
       </el-form-item>
       <el-form-item :label="$t('musicalInstrument')">
       	<el-cascader :options="musicalList" :placeholder="$t('pleaseSelectSongUseInstrument')" :props="{multiple:false}" v-model="musicalValue"
       		@change="changeMusicalCascader" popper-class="reset-popper-bg-color" clearable></el-cascader>
       </el-form-item>
       <el-form-item :label="$t('emotion')">
       	<el-cascader :options="moodList" :placeholder="$t('pleaseSelectSongMood')" :props="{multiple:false}" v-model="moodValue"
       		@change="changeMoodCascader" popper-class="reset-popper-bg-color" clearable></el-cascader>
       </el-form-item>
        <el-form-item label="">
          <el-checkbox v-model="checked">{{ $t("readAndAgree") }}
          </el-checkbox>
          <span @click="showAgreement(4)" style="color: #3875FB; cursor: pointer;">《{{
              $t('musicianOccupancyAgreement')
            }}》</span>

        </el-form-item>
        <div class="submit">
          <el-button @click="toissue" type="primary">{{ $t("publishWork") }}</el-button>
        </div>
      </el-form>
    </div>
	<!-- 协议弹窗 -->
	<AgreementPopup ref="popupAgreement"></AgreementPopup>
  </div>
</template>

<script>
	import {
		addMusic,
		keyList
	} from "@/api/Upfile"
	import {
		tagList
	} from "@/api/Home";
	import {
		suitList
	} from "@/api/Common";
	import { getMyInfo } from "@/utils/common/user";
	import AgreementPopup from "@/components/popup/AgreementPopup.vue"
export default {
  props: {},
  data() {
    return {
		disabled: false,
      ruleForm: {
		  title: '',
		  creator: '',
		  url: '',
		  urlMR: '',
		  urlMulti: '',
		  key: '',
		  bpm: '',
		  agreeType: 4,
        // name: '',
        // region: '',
        // date1: '',
        // date2: '',
        // delivery: false,
        // type: [],
        // resource: '',
        // desc: '',
      },
      rules: {
        name: [
          {required: true, message: this.$t('inputactivename'), trigger: 'blur'},
          {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        region: [
          {required: true, message: this.$t('selectactivearea'), trigger: 'change'}
        ],
        date1: [
          {type: 'date', required: true, message: this.$t('selectdata'), trigger: 'change'}
        ],
        date2: [
          {type: 'date', required: true, message: this.$t('pleaseselecttime'), trigger: 'change'}
        ],
        type: [
          {type: 'array', required: true, message: this.$t('atleastselectactive'), trigger: 'change'}
        ],
        resource: [
          {required: true, message: this.$t('selectactivereasource'), trigger: 'change'}
        ],
        desc: [
          {required: true, message: this.$t('inputactivemethods'), trigger: 'blur'}
        ]
      },
	  // imageUrl: '', //图片路径
	  // checked: false,
	  uploadMusicFileUrl: process.env.VUE_APP_UPLOAD_MUSIC_URL, //上传AR
	  uploadImageUrl: process.env.VUE_APP_UPLOAD_IMAGE_URL, //上传图片路径
      fileList: [],
	  fileListMR: [],
	  fileListMulti: [],
      imageUrl: '',//图片路径
      // options: [
      //   {
      //     value: 1,
      //     label: '男歌手',
      //   },
      //   {
      //     value: 2,
      //     label: '女歌手'
      //   },
      //   {
      //     value: 3,
      //     label: '男女歌手'
      //   }
      // ],
      checked: false,
	  keysarr: [],
	  keyValue: [],
	  styleList: [],
	  singerList: [],
	  musicalList: [],
	  moodList: [],
	  singerValue: [],
	  styleValue: [],
	  musicalValue: [],
	  moodValue: [],
	  imageUrl1: '',
	  activeInfo: {},
    }
  },
  components: {
	  AgreementPopup
  },
  computed: {
	  styleValues() {
	  	return this.styleValue.map((item) => {
	  		if (_.isArray(item)) {
	  			return _.last(item);
	  		} else {
	  			return item;
	  		}
	  	});
	  },
  },
  created() {
  },
  mounted() {
	  this.activeInfo = this.$route.query
	  this.ruleForm.title = this.activeInfo.title
	  
	  this.getKeyList()
	  this.initTagList()
	  // console.log(this.$store.state, 'this.$store.state')
	  // this.ruleForm.creator = this.$store.state.loginname;
	  let userInfo = JSON.parse(this.getMyInfo())
	  this.$store.state.loginname = userInfo.name;
	  this.ruleForm.creator = userInfo.name;
	  console.log(process.env.VUE_APP_FILE_PATH, 'process.env.VUE_APP_FILE_PATH')
  },
  watch: {},
  methods: {
	  getMyInfo,
	  showAgreement(type) {
	    this.agreeType = type
	    this.$refs.popupAgreement.open()
	    console.log('协议',this.agreeType,'2222',type,'1111')
	    this.$refs.popupAgreement.getAgreement(this.agreeType)
	  },
	  // 音调列表
	  getKeyList(){
		  keyList().then(res => {
			  console.log()
		  	let keys = Object.assign(res.data.list[1], res.data.list[2])
		  
		  	for (let key in keys) {
		  		let obj = {}
		  		// obj.key = keys[key]
		  		// obj.toneId = key
				obj.label = keys[key]
				obj.value = key
		  		this.keysarr.push(obj)
		  	}
			
			// let singerOption = {}
			// for (const key in res.data.list.suit_arr) {
			// 	console.log(key)
			// 	singerOption.value = key
			// 	singerOption.label = res.data.list.suit_arr[key]
			// 	this.singerList.push(JSON.parse(JSON.stringify(singerOption)))
			// }
		  
		  })
		  console.log(this.keysarr,'getKeyList')
	  },
	  // 上传AR成功
	  callbackAR(res) {
	  	this.ruleForm.url = this.uploadMusicFileUrl + '/' +  res.data.url;
	  	console.log(this.ruleForm.url,'this.ruleForm.ur')
	  },
	  // AR格式校验
	  testformatAr(file) {
	  	const ar_isWAV = file.type === "audio/wav";
	  	const ar_isMP3 = file.type === "audio/mpeg";
	  	const ar_isLt100M = file.size / 1024 / 1024 < 100;
	  	// if( ar_isWAV || ar_isMP3){
	  	if (ar_isMP3) {
	  	  if (!ar_isLt100M) {
	  	    this.$message({
	  	      type: "error",
	  	      message: this.$t("Collaboration.toobig"),
	  	    });
	  	    return false;
	  	  } else {
	  	  }
	  	} else {
	  	  this.$message({
	  	    type: "error",
	  	    message: this.$t("Collaboration.rightAr"),
	  	  });
	  	  return false;
	  	}
	  },
	  // 移出AR
	  removeAR() {
	  	this.$refs.AR.clearFiles();
	  },
	  // AR预览
	  handlePreview(file) {
	  	console.log(file);
	  },
	  // AR移出前
	  beforeRemove(file, fileList) {
	  	return this.$confirm(`${$t("confirmDel")} ${file.name}？`);
	  },
	  // AR 
	  handleExceed(files, fileList) {
	  	this.$message.warning(
	  		`${$t("limitselection")} ${files.length} ${$t("Selected")} ${files.length + fileList.length} ${$t("filenum")}`);
	  },
	  // 上传MR成功
	  callbackMR(res) {
	  	this.ruleForm.urlMR = this.uploadMusicFileUrl + '/' +  res.data.url;
	  	console.log(this.ruleForm.urlMR,'this.ruleForm.urlMR')
	  },
	  // MR校验格式
	  testformatMr(file) {
	  	const ar_isWAV = file.type === "audio/wav";
	  	const ar_isMP3 = file.type === "audio/mpeg";
	  	const ar_isLt100M = file.size / 1024 / 1024 < 100;
	  	// if( ar_isWAV || ar_isMP3){
	  	if (ar_isMP3) {
	  	  if (!ar_isLt100M) {
	  	    this.$message({
	  	      type: "error",
	  	      message: this.$t("Collaboration.toobig"),
	  	    });
	  	    return false;
	  	  } else {
	  	  }
	  	} else {
	  	  this.$message({
	  	    type: "error",
	  	    message: this.$t("Collaboration.rightmr"),
	  	  });
	  	  return false;
	  	}
	  },
	  // 移出MR
	  removeMR() {
	  	this.$refs.MR.clearFiles();
	  },
	  // MR预览
	  handlePreviewMR(file) {
	  	console.log(file);
	  },
	  // MR移出前
	  beforeRemoveMR(file, fileList) {
	  	return this.$confirm(`${$t("confirmDel")} ${file.name}？`);
	  },
	  // MR
	  handleExceedMR(files, fileList) {
	  	this.$message.warning(
			`${$t("limitselection")} ${files.length} ${$t("Selected")} ${files.length + fileListMR.length} ${$t("filenum")}`);
	  },
	  // 上传Multi成功
	  callbackMulti(res) {
	  	this.ruleForm.urlMulti = this.uploadMusicFileUrl + '/' +  res.data.url;
	  	console.log(this.ruleForm.urlMulti,'this.ruleForm.urlMulti')
	  },
	  // Multi校验格式
	  testformatMulti(file) {
	  	const ar_isWAV = file.type === "audio/wav";
	  	const ar_isMP3 = file.type === "audio/mpeg";
	  	const ar_isLt100M = file.size / 1024 / 1024 < 100;
	  	// if( ar_isWAV || ar_isMP3){
	  	if (ar_isMP3) {
	  	  if (!ar_isLt100M) {
	  	    this.$message({
	  	      type: "error",
	  	      message: this.$t("Collaboration.toobig"),
	  	    });
	  	    return false;
	  	  } else {
	  	  }
	  	} else {
	  	  this.$message({
	  	    type: "error",
	  	    message: this.$t("Collaboration.rightmulti"),
	  	  });
	  	  return false;
	  	}
	  },
	  // 移出Multi
	  removeMulti() {
	  	this.$refs.Multi.clearFiles();
	  },
	  // Multi预览
	  handlePreviewMulti(file) {
	  	console.log(file);
	  },
	  // Multi移出前
	  beforeRemoveMulti(file, fileList) {
	  	return this.$confirm(`${$t("confirmDel")} ${file.name}？`);
	  },
	  // Multi
	  handleExceedMulti(files, fileList) {
	  	this.$message.warning(
			`${$t("limitselection")} ${files.length} ${$t("Selected")} ${files.length + fileListMulti.length} ${$t("filenum")}`);
			
	  },
	  changeKeyCascader(val){
		  // if (val.length > 1) {
		  // 	this.keyValue = val.pop();
		  // }
	  },
	  changeSingerCascader(val) {
	  	// console.log(val, 'changeSingerCascader')
	  	// // console.log(val)
	  	// // this.singerValue = val
	  	// if (val.length > 1) {
	  	// 	this.singerValue = val.pop();
	  	// }
	  },
	  changeStyleCascader(val) {
	  	console.log(val, 'changeStyleCascader')
	  	console.log(val)
	  	console.log(this.styleList)
	  	this.styleList = this.setStyleSelect(this.styleList);
	  
	  },
	  setStyleSelect(items) {
	  	items.forEach((item, index) => {
	  		const disable = this.styleValue.length >= 4;
	  		items[index].disabled =
	  			this.styleValues.indexOf(item.value) > -1 ? false : disable;
	  	});
	  	return items;
	  },
	  changeMusicalCascader(val) {
	  	// console.log(val)
	  	// if (val.length > 1) {
	  	// 	this.musicalValue = val.pop();
	  	// }
	  },
	  changeMoodCascader(val) {
	  	// console.log(val)
	  	// if (val.length > 1) {
	  	// 	this.moodValue = val.pop();
	  	// }
	  },
	  
	  
	  // 上传图片成功
	  callbackcover(res,file) {
	  	console.log(res, 'rrrrrrrrrr')
	  	// this.imageUrl = process.env.VUE_APP_FILE_PATH + '/' + res.data.url;
		this.imageUrl = URL.createObjectURL(file.raw);
		this.image1 = res.data.url
		// this.imageUrl1 = res.data.url;
	  	console.log(this.imageUrl)
	  },
	  // 移除图片
	  handleRemove(file) {
	  	this.$refs.upload.clearFiles();
	  },
	  testformatCover(file) {
	  	const cover_isJPG = file.type === "image/jpeg";
	  	const cover_isPNG = file.type === "image/png";
	  	const cover_isLt10M = file.size / 1024 / 1024 < 10;
	  	if (cover_isJPG || cover_isPNG) {
	  		if (!cover_isLt10M) {
	  			this.$message({
	  				type: "error",
	  				message: this.$t("Collaboration.toobig"),
	  			});
	  			return false;
	  		} else {}
	  	} else {
	  		this.$message({
	  			type: "error",
	  			message: this.$t("Collaboration.rightcover"),
	  		});
	  		return false;
	  	}
	  },
	 
    // submitForm(formName) {
    //   this.$refs[formName].validate((valid) => {
    //     if (valid) {
    //       alert('submit!');
    //     } else {
    //       console.log('error submit!!');
    //       return false;
    //     }
    //   });
    // },
    // resetForm(formName) {
    //   this.$refs[formName].resetFields();
    // },
    // handleRemove(file, fileList) {
    //   console.log(file, fileList);
    // },
    // // handlePreview(file) {
    // //   console.log(file);
    // // },
    // handleExceed(files, fileList) {
    //   this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    // },
    // beforeRemove(file, fileList) {
    //   return this.$confirm(`确定移除 ${file.name}？`);
    // },
    // handleAvatarSuccess(res, file) {
    //   this.imageUrl = URL.createObjectURL(file.raw);
    // },
    // beforeAvatarUpload(file) {
    //   const isJPG = file.type === 'image/jpeg';
    //   const isLt2M = file.size / 1024 / 1024 < 2;

    //   if (!isJPG) {
    //     this.$message.error('上传头像图片只能是 JPG 格式!');
    //   }
    //   if (!isLt2M) {
    //     this.$message.error('上传头像图片大小不能超过 2MB!');
    //   }
    //   return isJPG && isLt2M;
    // },
	initTagList() {
		tagList({})
			.then((res) => {
				console.log('获取标签信息成功:', res.data)
				//风格
				let styleOption = {}
				for (let i = 0; i < res.data.list.genre_arr.length; i++) {
					if (res.data.list.genre_arr[i].child) {
						for (let j = 0; j < res.data.list.genre_arr[i].child.length; j++) {
							styleOption.value = res.data.list.genre_arr[i].child[j].id
							styleOption.label = res.data.list.genre_arr[i].child[j].name
							styleOption.disabled = false
							this.styleList.push(JSON.parse(JSON.stringify(styleOption)))
						}
					}
				}
				//歌手
				let singerOption = {}
				for (const key in res.data.list.suit_arr) {
					console.log(key)
					singerOption.value = key
					singerOption.label = res.data.list.suit_arr[key]
					this.singerList.push(JSON.parse(JSON.stringify(singerOption)))
				}
				//乐器
				let musicOption = {}
				for (const key in res.data.list.instrument_arr) {
					console.log(key)
					musicOption.value = res.data.list.instrument_arr[key].id
					musicOption.label = res.data.list.instrument_arr[key].name
					this.musicalList.push(JSON.parse(JSON.stringify(musicOption)))
				}
				//情绪
				let moodOption = {}
				for (const key in res.data.list.mood_arr) {
					console.log(key)
					moodOption.value = res.data.list.mood_arr[key].id
					moodOption.label = res.data.list.mood_arr[key].name
					this.moodList.push(JSON.parse(JSON.stringify(moodOption)))
				}
			})
			.catch((err) => {
				console.log(err, "所有标签列表失败");
			});
		suitList({}).then(res => {
			// console.log(res, 'ressssss')
			// let arr = []
			// let obj = res.data.list
			// for(var key in obj){
			//     arr.push({id:key,name:obj[key]})
			// }
			// console.log(arr,'arrrrrrrrrr')
			// // this.singerList = arr
			console.log('获取适合歌手成功:', res)
	
		}).catch(err => {
			console.log('获取适合歌手失败:', err)
		})
	},
    // showAgreement() {
    //   console.log('协议')
    // },
	toissue() {
		if (this.checked === false) {
			this.$message({
				type: "error",
				message: this.$t("checkmusicianagreement"),
			});
			return false
		}
		let params = {
			title: this.ruleForm.title,
			recommend: 1,
			url: this.ruleForm.url,
			multi: this.ruleForm.urlMulti,
			// images: this.imageUrl,
			images: this.image1,
			suit: this.singerValue.join(""),
			GenresId: this.styleValue.join(","),
			InstrumentId: this.musicalValue.join(""),
			moodId: this.moodValue.join(""),
			prize: '',
			bpm: this.ruleForm.bpm,
			mr: this.ruleForm.urlMR,
			// singer: this.singer,
			// lyrics: this.composing,
			// composition: this.composition,
			// arrangements: this.arrangement,
			toneId: this.keyValue.join(""),
			activity: this.activeInfo.activityId,
			cases: this.activeInfo.musicId
			// custom_code: this.custom_code || '',
		}
		addMusic(params).then(res => {
			if (res.status == 1) {
				this.$message({
					type: 'success',
					message: res.msg
				})
				this.successdialog = true
				this.$router.push("/personalCenter/myWork/workseademo");
			} else {
				for (let item in res.data) {
					setTimeout(() => {
						this.$notify({
							title: this.$t("demoup_notify_title"),
							type: 'error',
							message: res.data[item]
	
						})
					}, 1500);
	
				}
			}
		})
		this.dialogVisible = false
	},
  }
}
</script>

<style scoped lang='less'>
	::v-deep .el-button--primary {
		border-color: #3370ff;
		/* color: @inputHoverColor; */
		background-color: #3370ff !important;
	
		&:hover {
			opacity: .8;
		}
	}
	.btn-wrap{
		display: flex;
		align-items: center;
	}
	.btn-wrap-text{
		padding-left: 10px;
	}
	/deep/.el-upload__input {
		display: none;
	}
	
	/deep/.el-upload {
		width: 80px;
		height: 80px;
		position: relative;
	}
	
	/deep/.el-icon-plus {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	
	}
	
	/deep/.el-upload-list__item-thumbnail {
		display: inline-block;
		width: 80px;
		height: 80px;
	}
	
	/deep/.el-upload-list--picture-card>.el-upload-list__item-actions {
		width: 80px;
		height: 80px;
	}
	
	/deep/.is-ready {
		width: 80px;
		height: 80px;
	}
	
	/deep/.el-upload-list--text>.el-upload-list__item {
		width: 300px;
		height: 30px;
		position: relative;
		top: -10px;
	}
	
	/deep/.el-upload-list--text {
		clear: both;
	}
	
	.upload-ar>div:nth-child(1) {
		float: left;
	}
	
	.upload-ar>div:nth-child(2) {
		float: right;
		margin-right: 300px;
	}
	
	/deep/.el-upload--text {
		height: 50px;
	}
	/deep/.coverup .is-success {
		width: 80px;
		height: 80px;
	}
	::v-deep .collaboration-area-form .avatar-uploader .el-upload {
		background: @recommendMusicianListBGColor;
	}
	
	::v-deep .el-upload .el-upload--picture-card {
		background: @recommendMusicianListBGColor;
		display: flex;
		align-items: center;
	}
	
	::v-deep .el-upload--picture-card {
		background: @recommendMusicianListBGColor;
		width: 80px;
		height: 80px;
	}

.collaboration-area {
  background-color: @recommendMusicianListBGColor;
  padding: 60px;
  margin-top: 16px;
  border-radius: 6px;

  &-form {
    width: 70%;
    margin: 0 auto;

    // 上传样式
    ::v-deep .avatar-uploader {
      display: flex;

      .el-upload {
        border: 1px dashed #fff;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &:hover {
          border-color: #3875FB;
        }
      }

    }

    ::v-deep .el-button--small {
      border: 1px solid #fff;
      color: @uploadFormTextColor;
      background-color: @uploadInputBackgroundColor !important;

      &:hover {
        border-color: #3875FB;
        color: #3875FB;
      }
    }

    ::v-deep .el-form-item__label {
      color: @uploadFormTextColor;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #fff;
      width: 80px;
      height: 80px;
      line-height: 80px;
      text-align: center;

      &:hover {
        color: #409EFF;
      }
    }

    .avatar {
      width: 80px;
      height: 80px;
      display: block;
    }

    ::v-deep .el-upload__tip {
      padding-top: 64px;
      margin-left: 8px;
      line-height: 16px;
    }

    // 修改cascader宽度
    ::v-deep .el-cascader {
      width: 100%;
    }

    // 修改input背景色
    ::v-deep .el-input__inner {
      height: 42px;
      background-color: @uploadInputBackgroundColor;
      border: 1px solid @uploadInputBackgroundColor;
	  color: @uploadFormTextColor;

      &:hover {
        border: 1px solid #fff;
      }
    }

    ::v-deep .el-form-item.is-error .el-input__inner,
    .el-form-item.is-error .el-input__inner:focus,
    .el-form-item.is-error .el-textarea__inner,
    .el-form-item.is-error .el-textarea__inner:focus,
    .el-message-box__input input.invalid,
    .el-message-box__input input.invalid:focus {
      border-color: #F56C6C;
    }

    ::v-deep .el-checkbox__label {
      color: @uploadFormTextColor !important;
    }

    ::v-deep .el-tag {
      background-color: rgba(255, 255, 255, .08);
    }

    .submit {
      display: flex;
      align-items: center; /*垂直方向居中*/
      justify-content: center; /*水平方向居中*/
    }
  }

}
</style>
